import gql from 'graphql-tag';

export const ContentRedirectQuery = gql`
	query ContentRedirectQuery(
		$contentId: ID
		$versionOverride: Int
		$includeAlias: Boolean = false
	) {
		content(id: $contentId, version: $versionOverride) {
			nodes {
				id
				title
				space {
					id
					homepageId
					key
					alias @include(if: $includeAlias)
				}
				history {
					createdDate
				}
			}
		}
	}
`;
